import request from '../request'

export const getMyself = async () => {
  const { body } = await request.get('customer')
  return body
}

export const getDealsTimeseries = async ({ exchangeAccountId }) => {
  const { body } = await request.get(
    `customer/exchanges/${exchangeAccountId}/deals/timeseries`
  )
  return body
}

export const getDealsTotals = async ({ exchangeAccountId }) => {
  const { body } = await request.get(
    `customer/exchanges/${exchangeAccountId}/deals/totals`
  )
  return body
}

export const getExchanges = async () => {
  const { body } = await request.get('customer/exchanges')
  return body
}
