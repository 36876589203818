import React from 'react'
import {
  PageWithHeader,
  TopNav,
  PageContent,
  Button,
  DropdownMenu
} from 'bumbag'
import { useAuth } from '~/modules/Auth/context'

export const Wrapper = React.forwardRef(({ children, ...rest }, ref) => {
  const { user, signOut } = useAuth()
  return (
    <PageWithHeader
      ref={ref}
      sticky
      header={
        <TopNav>
          <TopNav.Section>
            <TopNav.Item
              href='https://wudan.cash'
              fontWeight='semibold'
              display='flex'
              alignItems='center'
            >
              <img
                alt='wudan.cash'
                src='/wudan.png'
                style={{ height: '40px', marginRight: '4px' }}
              />
            </TopNav.Item>
          </TopNav.Section>
          <TopNav.Section marginRight='major-2'>
            {!!user && (
              <TopNav.Item>
                <DropdownMenu
                  menu={
                    <DropdownMenu.Item
                      iconBefore='solid-sign-out-alt'
                      onClick={signOut}
                    >
                      Sign Out
                    </DropdownMenu.Item>
                  }
                >
                  <Button iconAfter='chevron-down'>
                    <span
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {user.first_name} {user.last_name}
                    </span>
                  </Button>
                </DropdownMenu>
              </TopNav.Item>
            )}
          </TopNav.Section>
        </TopNav>
      }
      {...rest}
      border='default'
    >
      {children}
    </PageWithHeader>
  )
})

export const Content = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <PageContent ref={ref} {...rest}>
      {children}
    </PageContent>
  )
})
