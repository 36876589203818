import React from 'react'
import { Heading, Tabs, Checkbox, Tooltip, Box } from 'bumbag'
import { Bar, Line } from 'react-chartjs-2'
import { useQuery } from 'react-query'
import { getDealsTotals, getExchanges } from '~/lib/api'
import theme from '~/lib/theme'
// import groupBy from 'lodash.groupby'
// import uniq from 'lodash.uniq'

// const COLORS = [
//   theme.palette.primary,
//   '#191970',
//   '#808000',
//   '#ffa500',
//   '#ffff00',
//   '#7fff00',
//   '#7f0000',
//   '#ba55d3',
//   '#00fa9a',
//   '#00ffff',
//   '#0000ff',
//   '#ff00ff',
//   '#1e90ff',
//   '#fa8072',
//   '#dda0dd',
//   '#ff1493',
//   '#87cefa',
//   '#2f4f4f',
//   '#ff0000',
//   '#2e8b57',
//   '#ffe4c4'
// ]

export default function Dashboard () {
  const { data: customerAccounts } = useQuery('exchanges', getExchanges)

  const [custAccountId, setCustAccountId] = React.useState(null)

  React.useEffect(() => {
    if (customerAccounts?.length > 0 && !custAccountId) {
      setCustAccountId(customerAccounts[0]._id)
    }
  }, [customerAccounts, custAccountId, setCustAccountId])

  if (!customerAccounts) return null

  return (
    <Tabs selectedTabId={custAccountId}>
      <Tabs.List>
        {customerAccounts.map(custAccount => (
          <Tabs.Tab tabId={custAccount._id} key={custAccount._id}>
            {customerAccounts.length === 1
              ? 'My FTX Account'
              : custAccount.exchangeAccount.name}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {customerAccounts
        .filter(cu => cu.isTradingEnabled)
        .map(custAccount => (
          <Tabs.Panel
            key={custAccount._id}
            tabId={custAccount._id}
            padding='major-2'
          >
            <AccountDetails custAccount={custAccount} />
          </Tabs.Panel>
        ))}
    </Tabs>
  )
}

const marketColorMap = new Map()

export function AccountDetails ({ custAccount }) {
  const { data } = useQuery(
    ['dealsTotals', custAccount.exchangeAccount._id],
    () => getDealsTotals({ exchangeAccountId: custAccount.exchangeAccount._id })
  )

  if (!data) return null

  const lastWeek = data.reports.slice(-7)
  const today = data.reports[data.reports.length - 1]

  return (
    <div>
      <div style={{ fontSize: '18px' }}>
        Account Value: <b>${data.balance.toFixed(2)}</b>
      </div>
      <Heading use='h5' marginTop='major-2'>
        Profit Summary
      </Heading>
      <ul style={{ paddingInlineStart: '16px' }}>
        <li>
          Today: <b>${today.pnl.toFixed(2)}</b> USD
        </li>
        <li>
          Last 7 Days:{' '}
          <b>${lastWeek.reduce((n, item) => n + item.pnl, 0).toFixed(2)}</b> USD
        </li>
        <li>
          Total: <b>${data.netPnl.toFixed(2)}</b> USD
        </li>
      </ul>
      <br />
      <Heading use='h5'>Total Profit Over Time</Heading>
      <Line
        data={{
          labels: data.reports.map(item => item.reportYmd),
          datasets: [
            {
              label: 'Total Profit USD',
              data: data.reports.map(item => item.netPnl.toFixed(2)),
              borderColor: theme.palette.primary,
              backgroundColor: 'transparent'
            }
          ]
        }}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 6
                }
              }
            ]
          }
        }}
      />
      <br />
      <br />
      <Heading use='h5'>Daily Profit over Last 7 Days</Heading>
      <Bar
        data={{
          labels: lastWeek.map(item => item.reportYmd),
          datasets: [
            {
              label: `Day's Profit USD`,
              data: lastWeek.map(item => item.pnl.toFixed(2)),
              backgroundColor: theme.palette.primary
            }
          ]
        }}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 6
                }
              }
            ]
          }
        }}
      />
      <div style={{ marginTop: '16px' }} />
    </div>
  )
}
