import React from 'react'
import { Provider as BumbagProvider, Box } from 'bumbag'
import theme from '~/lib/theme'
import config from '~/lib/config'
import * as Layout from '~/components/Layout'
import { QueryClientProvider, QueryClient } from 'react-query'
import { useAuth, AuthProvider } from '~/modules/Auth/context'

import TelegramLoginButton from 'react-telegram-login'

import Dashboard from '../Dashboard'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      retry: false
    }
  }
})

export default function AppWrapper () {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BumbagProvider theme={theme}>
          <App />
        </BumbagProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

function App () {
  const { signIn, isAuthenticated } = useAuth()
  return (
    <Layout.Wrapper>
      <Layout.Content>
        {!isAuthenticated ? (
          <Box display='flex' justifyContent='center'>
            <TelegramLoginButton
              dataOnauth={signIn}
              botName={config.telegram.bot}
            />
          </Box>
        ) : (
          <Dashboard />
        )}
      </Layout.Content>
    </Layout.Wrapper>
  )
}
