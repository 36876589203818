import { faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default {
  palette: {
    primary: '#00BF6F',
    primaryInverted: 'white',
    secondary: '#00AFD1'
  },
  iconSets: [
    {
      icons: [faChevronDown, faSignOutAlt],
      prefix: 'solid-',
      type: 'font-awesome'
    }
  ]
}
