import React from 'react'
import qs from 'qs'
import { useLocalStorage } from 'react-use'

const AuthContext = React.createContext()

export const getTelegramUser = () => window.authContext?.user
export const signOut = () => window.authContext?.signOut()

export const useAuth = () => React.useContext(AuthContext)

const queryUser = qs.parse(window.location.search.substring(1))
let initialized = false

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('wudan-user', null)

  if (!initialized) {
    initialized = true
    if (queryUser && queryUser.hash) {
      setUser(queryUser)
      window.history.replaceState('', '', '/')
    }
  }

  const signIn = React.useCallback(
    value => {
      setUser(value)
    },
    [setUser]
  )

  const signOut = React.useCallback(() => {
    setUser(null)
    window.location.reload()
  }, [setUser])

  // Save to window for debugging
  const value = (window.authContext = React.useMemo(
    () => ({
      user,
      signIn,
      signOut,
      isAuthenticated: !!user
    }),
    [user, signIn, signOut]
  ))

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
