import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './modules/App'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

window.apiRequest = require('./lib/request').default
