import fetch from 'unfetch'
import capitalize from 'lodash/capitalize'
import qs from 'qs'
import urlJoin from 'url-join'
import { getTelegramUser, signOut } from '~/modules/Auth/context'
import config from './config'
;['get', 'post', 'put', 'patch', 'head', 'delete'].forEach(method => {
  request[method] = function runMethodRequest (path, options = {}) {
    return request(path, { ...options, method: method.toUpperCase() })
  }
})

// Shortcut for debugging
window.apiRequest = request

export default request

async function request (path, options = {}) {
  options = {
    ...options,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-telegram-user': btoa(
        encodeURIComponent(JSON.stringify(getTelegramUser()))
      ),
      ...options.headers
    }
  }

  if (
    options.body &&
    typeof options.body !== 'string' &&
    options.headers['Content-Type'] === 'application/json'
  ) {
    options.body = JSON.stringify(options.body)
  }

  if (!/^https?/.test(path)) {
    path = urlJoin(config.api.base, path)
  }

  if (options.query) {
    path = `${path}?${qs.stringify(options.query)}`
  }

  const res = await fetch(path, options)
  try {
    res.body = await res.json()
  } catch (_) {}

  if (!res.ok) {
    const error = new Error(
      (res.body && res.body.message) || parseErrorMessage(res)
    )
    if (res.statusCode === 401) {
      signOut()
    }
    Object.assign(error, res)
    throw error
  }
  return res
}

// TODO:
// Parse error response and output list of errors.
function parseErrorMessage (res) {
  const parseErrorObject = obj => {
    const child = obj.errors && obj.errors[0]
    if (child && child.errors) {
      return parseErrorObject(child)
    }
    if (child && child.message) {
      return (
        child.path.map(value => capitalize(value)).join(' / ') +
        ': ' +
        child.message
      )
    }
    return 'Operation failed, please try again.'
  }
  return res.body ? parseErrorObject(res.body) : undefined
}
